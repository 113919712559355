import './components/animated-route';

/**
 * External Dependencies
 */
import React, { Component } from 'react';
import axios from 'axios';
import thunk from 'redux-thunk';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import WebFont from 'webfontloader';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { withRouter, Route, BrowserRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { KBarProvider } from 'kbar';
import { jitsuClient } from '@jitsu/sdk-js';

/**
 * Internal Dependencies
 */
import reducers from './reducers';
import { isSignPage, isPublicPage, isAdminPage, isExceptionPage, propsRedux, logger } from './utils';
import { updateAuth as actionUpdateAuth } from './actions';
import { currentUser as updateCurrentUser, isHavingAccessToCreateProduct as updateAccess } from './actions/users';
import { fundraisingSettings as updateFundraisingSetting } from './actions/fundraisings';
import { linkTree as updateLinkTree } from './actions/linktree';
import { getFonts } from './assets/linktree-themes';
// import Chatwoot from './components/chatwoot';

import Routes from './Routes';
import PageYaybar from './components/page-yaybar';
import PageYaybarAdmin from './components/page-yaybar-admin';
import PageNavbar from './components/page-navbar';
import PageToasts from './components/page-toasts';
import Kbar from './components/kbar';
import { actions } from './components/kbar/config';
const createStoreWithMiddleware =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? applyMiddleware(thunk, logger, propsRedux)(createStore)
    : applyMiddleware(thunk, propsRedux)(createStore);

const $html = window.jQuery('html');
const $body = window.jQuery('body');

/**
 * Component PageWrap
 */
class PageWrap extends Component {
  constructor(props) {
    super(props);

    this.maybeCheckAuth = this.maybeCheckAuth.bind(this);
    this.maybeUpdateGlobalSettings = this.maybeUpdateGlobalSettings.bind(this);
    this.maybeScrollPageToTop = this.maybeScrollPageToTop.bind(this);
    this.intervalId = null;

    // Add a request interceptor
    axios.interceptors.request.use(config => {
      const token = Cookies.get('token');
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      config.headers['Content-Type'] = 'application/json';
      return config;
    });

    // Add a response interceptor
    axios.interceptors.response.use(
      resp => {
        return resp;
      },
      err => {
        if (err && err.response && (419 === err.response.status || 401 === err.response.status)) {
          //const { updateAuth } = this.props;
          document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${process.env.REACT_APP_DOMAIN_URL};`;
          document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }

        return Promise.reject(err);
      }
    );
  }

  componentDidMount() {
    // register service worker
    if ('serviceWorker' in global.navigator) {
      global.navigator.serviceWorker
        .register('/sw.js')
        .then(registration => {
          // eslint-disable-next-line no-console
          console.log('service worker registered: ', registration.scope);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('error register service worker: ', error);
        });
    }

    // Load all the fonts
    WebFont.load({ google: { families: getFonts() } });

    this.maybeCheckAuth();
    this.maybeUpdateGlobalSettings();
    this.setCurrentUser();
    this.intervalId = setInterval(() => {
      const token = Cookies.get('token');
      if (token) {
        const decode = jwtDecode(token);
        const now = Date.now() / 1000;
        if (now > decode.exp) {
          this.refreshUser();
        }
      }
    }, 1000);

  }

  componentDidUpdate(prevProps) {
    this.maybeCheckAuth(prevProps);
    this.maybeUpdateGlobalSettings(prevProps);
    this.maybeScrollPageToTop(prevProps);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  maybeCheckAuth(prevProps) {
    const { auth, updateAuth, history } = this.props;
    const referrerUrl = auth.referrer && !isSignPage(auth.referrer) ? auth.referrer : '/';
    let newRedirect = false;

    // Redirect from Auth page for sub account.
    if (isSignPage() && auth.token && window.location.href.includes('/sign-in/sub/')) {
      document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${process.env.REACT_APP_DOMAIN_URL};`;
      document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      newRedirect = window.location.pathname;
      // Redirect from Auth page.
    } else if (isSignPage() && auth.token && window.location.pathname.slice(0, 18) !== '/sign-in/referral/') {
      newRedirect = referrerUrl;

      // Redirect to Auth page.
    } else if (!isSignPage() && !auth.token && !isPublicPage()) {
      newRedirect = '/sign-in';

      // Check if use logged out or logged in.
    } else if (prevProps && auth.token !== prevProps.auth.token) {
      newRedirect = auth.token ? referrerUrl : '/sign-in';

      // Check if url has #
    } else if (window.location.hash) {
      newRedirect = window.location.hash.replace(/^#/g, '');
    }

    // Redirect.
    if (newRedirect) {
      if (window.location.hash.replace(/^#/g, '').slice(0, 8) === '/sign-in' && !auth.token) {
        newRedirect = window.location.hash ? window.location.hash.replace(/^#/g, '') : window.location.pathname;
      } else {
        updateAuth({
          referrer: window.location.hash ? window.location.hash.replace(/^#/g, '') : window.location.pathname
        });
        history.push(newRedirect);
      }
    }
  }

  setCurrentUser() {
    this.refreshUser();
  }

  refreshUser() {
    const { currentUser, linkTree, fundraisingSettings, isHavingAccessToCreateProduct } = this.props;
    const token = Cookies.get('token');

    if (token) {
      const registerCoupon = new URLSearchParams(window.location.search).get('campaign');
      const registerReferral = new URLSearchParams(window.location.search).get('ma');
      axios
        .get(
          `${process.env.REACT_APP_PROXY}/me${registerReferral ? `?registerReferral=${registerReferral}` : ''}${
            registerCoupon ? `?registerCoupon=${registerCoupon}` : ''
          }`,
          {
            withCredentials: true
          }
        )
        .then(res => {
          if (!res || res.status === 401) {
            document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${process.env.REACT_APP_DOMAIN_URL};`;
            document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
            return window.location.reload();
          }
          if (res.data.isMerchantModeDisabled) {
            window.localStorage.setItem(`account-mode:${res.data.id}`, 'affiliator');
          }
          currentUser(res.data);
          linkTree(res.data.linkTree);
          fundraisingSettings(res.data.fundraisingSettings);

          //init
          const jitsu = jitsuClient({
            key: process.env.REACT_APP_JITSU_KEY,
            tracking_host: 'https://t.jitsu.com'
          });

          //identify user
          jitsu.id({
            email: res.data?.email,
            internal_id: res.data?.id
          });

          //track page views
          jitsu.track('app_page');

          if (res.data.role === 'admin') {
            const checkProductAccess = JSON.parse(res.data.path)
              .filter(item => item.checked)
              .map(item => item.name)
              .includes('Buat / Edit Produk');

            if (!checkProductAccess) {
              isHavingAccessToCreateProduct(false);
            }
          }
        })
        .catch(() => {
          document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${process.env.REACT_APP_DOMAIN_URL};`;
          document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
          window.location.reload();
        });
    }
  }

  maybeUpdateGlobalSettings(prevProps) {
    const { settings } = this.props;

    // night mode.
    if (prevProps && prevProps.settings.night_mode !== settings.night_mode) {
      if (settings.night_mode) {
        $html.addClass('rui-night-mode');

        // eslint-disable-next-line no-unused-expressions
        import('./style-night.scss');
      } else {
        $html.removeClass('rui-night-mode');
      }
    }
    if (!prevProps && settings.night_mode) {
      $html.addClass('rui-night-mode');

      // eslint-disable-next-line no-unused-expressions
      import('./style-night.scss');
    }

    // spitlight mode.
    if (prevProps && prevProps.settings.spotlight_mode !== settings.spotlight_mode) {
      if (settings.spotlight_mode) {
        $body.addClass('rui-spotlightmode');
      } else {
        $body.removeClass('rui-spotlightmode');
      }
    }

    if (!prevProps && settings.spotlight_mode) {
      $body.addClass('rui-spotlightmode');
    }

    // section lines.
    if (prevProps && prevProps.settings.show_section_lines !== settings.show_section_lines) {
      if (settings.show_section_lines) {
        $body.addClass('rui-section-lines');
      } else {
        $body.removeClass('rui-section-lines');
      }
    }

    if (!prevProps && settings.show_section_lines) {
      $body.addClass('rui-section-lines');
    }

    // sidebar small.
    if (prevProps && prevProps.settings.sidebar_small !== settings.sidebar_small) {
      if (settings.sidebar_small) {
        $body.addClass('yay-hide');
      } else {
        $body.removeClass('yay-hide');
      }
    }

    if (!prevProps && settings.sidebar_small) {
      $body.addClass('yay-hide');
    }
  }

  maybeScrollPageToTop(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  render() {
    const { auth, location } = this.props;

    return (
      <TransitionGroup>
        <PageToasts />
        {isAdminPage() ? (
          <Route>{auth.token && <Route component={PageYaybarAdmin} />}</Route>
        ) : (
          <Route>
            {auth.token && window.location.pathname.slice(0, 18) !== '/sign-in/referral/' && !isExceptionPage() && (
              <>
                <Route component={PageNavbar} />
                <Route component={PageYaybar} />
              </>
            )}
          </Route>
        )}

        <CSSTransition key={location.pathname} timeout={300} classNames="rui-router-transition" unmountOnExit>
          <Routes location={location} />
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

const PageWrapWithState = connect(
  ({ auth, settings, currentUser, linkTree, fundraisingSettings, isHavingAccessToCreateProduct }) => ({
    auth,
    settings,
    currentUser,
    linkTree,
    fundraisingSettings,
    isHavingAccessToCreateProduct
  }),
  {
    updateAuth: actionUpdateAuth,
    currentUser: updateCurrentUser,
    linkTree: updateLinkTree,
    fundraisingSettings: updateFundraisingSetting,
    isHavingAccessToCreateProduct: updateAccess
  }
)(withRouter(PageWrap));

/**
 * Component App
 */
class App extends Component {
  constructor(props) {
    super(props);

    // create redux store.
    this.store = createStoreWithMiddleware(
      reducers,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

    const multipleHttpLink2 = new RetryLink().split(
      operation => operation.getContext().upload,
      createUploadLink({ uri: process.env.REACT_APP_PROXY }),
      new HttpLink({ uri: `${process.env.REACT_APP_PROXY}` })
    );

    const retConfig = {
      delay: {
        initial: 400,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 1
      }
    };

    const multipleHttpLink = new RetryLink(retConfig).split(
      operation => operation.getContext().v2 || operation.getContext().public,
      new HttpLink({ uri: `${process.env.REACT_APP_PROXY}` }),
      multipleHttpLink2
    );

    const httpLink = new RetryLink(retConfig).split(
      operation => {
        const token = Cookies.get('token');
        const customerToken = Cookies.get('customerToken')?.split(',')[0];
        if (operation.getContext().public) {
          operation.setContext({
            headers: {}
          });
        } else if (operation.getContext().v2) {
          if (!isPublicPage()) {
            if (!token) {
              return window.location.reload();
            }
          }

          const t = isPublicPage() ? customerToken : token;
          operation.setContext({
            headers: {
              authorization: t ? `Bearer ${t}` : ''
            }
          });
        } else if (operation.getContext().gographql) {
          return operation.getContext().gographql;
        } else {
          if (!token) {
            return window.location.reload();
          }

          operation.setContext({
            headers: {
              authorization: token ? `Bearer ${token}` : ''
            }
          });
        }
      },

      new HttpLink({ uri: `${process.env.REACT_APP_GOGRAPHQL_SERVER_URL}` }),
      multipleHttpLink
    );

    const authLink = setContext((_, { headers }) => {
      const token = Cookies.get('token');
      // get the authentication token from local cookie if it exists
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      };
    });

    const errorLink = onError(({ networkError }) => {
      // token expired and we need to renew the token by redirecting the browser to oauth page
      if (networkError && networkError.statusCode === 419) {
        document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${process.env.REACT_APP_DOMAIN_URL};`;
        document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        window.location.reload();
      } else if (networkError && networkError.statusCode === 401) {
        // token no longer valid, force logout
        document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${process.env.REACT_APP_DOMAIN_URL};`;
        document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        window.location.reload();
      }
    });

    this.client = new ApolloClient({
      cache: new InMemoryCache({
        // VERY IMPORTANT!!
        // we're using table with name=Subscription and it will causing issue on apollo when using ___typename for caching
        // I think it because apollo has reserved word 'Subscription'
        addTypename: false
      }),
      link: ApolloLink.from([errorLink, authLink, httpLink])
    });
  }

  render() {
    return (
      <ApolloProvider client={this.client}>
        <Provider store={this.store}>
          {/* {window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1' && <Chatwoot />} */}

          <KBarProvider actions={actions}>
            <Kbar />
            <BrowserRouter>
              <PageWrapWithState />
            </BrowserRouter>
          </KBarProvider>
        </Provider>
      </ApolloProvider>
    );
  }
}

export default App;
