import React, { useState } from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { updatePaymentLinks } from '../../actions/payment-links';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  FormFeedback,
} from 'reactstrap';
import DatePicker from '../date-time-picker';
import { GQL_PAYMENTLINK_CREATE_BY_SLUG, GQL_PAYMENTLINK_UPDATE_BY_SLUG } from '../../gqls';
import {
  validator,
  paymentLinkUrl,
  slug,
  cohortCategory,
  sanitizeDOM,
  paymentTypeOptions
} from '../../utils';
import Icon from '../icon';
import { REGEX_SLUG } from '../../utils/regex';
import SubmitButton from '../submit-button';
import EmbedViewer from '../embed-viewer';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import RichEditor, { getContentString, getEditorContent, getPlainText } from '../rich-text-editor';
import MultipleImageInput from '../multiple-image-input';

const ModalBodyCohortCourse = ({ isOpen = false, onClose, onSuccess, classData }) => {
  const dispatch = useDispatch();

  const today = new Date();
  const tomorrow = new Date(today);
  const { register, handleSubmit, errors, getValues, control } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [link, setLink] = useState(classData ? classData.link : '');
  const [embedUrlData, setEmbedUrlData] = useState(classData?.embedUrl);
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);
  const [paymentType, setPaymentType] = useState(classData?.paymentType || 'paid');

  const [multipleImageId, setMultipleImageId] = useState('');
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const isUpdate = Boolean(classData);

  const [insertPaymentLink, { loading: submitCreateLoading }] = useMutation(GQL_PAYMENTLINK_CREATE_BY_SLUG);
  const [editPaymentLink, { loading: submitUpdateLoading }] = useMutation(GQL_PAYMENTLINK_UPDATE_BY_SLUG);

  if (!classData) classData = {};

  const onSubmit = async values => {
    if (isDescriptionEmpty) return;
    values.description = sanitizeDOM(getContentString(values.description));

    if (values.paymentType === 'free') {
      values.amount = 0;
    } else if (values.paymentType === 'voluntary' && !values.amount) {
      values.amount = 0;
    } else {
      values.amount = parseFloat(values.amount);
    }

    values.limit = values.limit ? parseInt(values.limit) : null;

    try {
      if (isUpdate) {
        values.id = classData.id;
        let statusUpdate = 'closed';
        if (!values.expiredAt || new Date(values.expiredAt) > Date.now()) {
          statusUpdate = 'active';
        }
        const objeditPaymentLinkBySlug = {
          ...values,
          expiredAt: values.expiredAt ? new Date(values.expiredAt).setHours(23, 59, 59, 0) : values.eventStartAt,
          gradeThreshold: values.gradeThreshold ? parseFloat(values.gradeThreshold) : null,
          status: statusUpdate,
          ...(multipleImageId && { multipleImageId })
        };

        await editPaymentLink({
          variables: {
            input: objeditPaymentLinkBySlug
          }
        });
        dispatch(updatePaymentLinks(Math.random()));
      } else {
        const objPaymentLink = {
          ...values,
          ...(multipleImageId && { multipleImageId }),
          expiredAt: values.expiredAt ? new Date(values.expiredAt).setHours(23, 59, 59, 0) : values.eventStartAt,
          gradeThreshold: values.gradeThreshold ? parseFloat(values.gradeThreshold) : null,
          link: slug(values.name),
          type: 'cohort_based',
          status: 'active',
          isBootcamp: false
        };

        const res = await insertPaymentLink({
          variables: {
            input: objPaymentLink
          }
        });
        // segment tracking
        onClose();
        onSuccess(res.data.insertPaymentLinkBySlug.id);
        window.analytics.track('Create Cohort-based', values);
      }
    } catch (error) {
      setSubmitError('Error! Silahkan coba lagi atau hubungi CS');
      window.analytics.track('Create Cohort-based - ERROR', values);
    }
  };

  const submitText = () => {
    if (isUpdate) {
      return 'Edit Kelas Cohort / Bootcamp';
    }

    return 'Buat Kelas Cohort / Bootcamp';
  };

  const categoryOptions = cohortCategory.map(value => ({ label: value, value }));

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Kelas Cohort / Bootcamp</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            Mengadakan kelas berkelompok (batch / cohort-based) dengan banyak sesi semakin mudah dengan Mayar
          </small>
        </p>
        <hr />
        {submitError && <Alert color="danger">Error! Silahkan coba lagi atau hubungi CS</Alert>}
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
          <FormGroup>
            <Label for="name">Judul / Nama Bootcamp*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={errors.name}
              defaultValue={classData.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="category">Kategori*</Label>
            <Controller
              name="category"
              control={control}
              defaultValue={classData.category}
              rules={{ required: validator.required }}
              render={({ onChange, name, value: defaultValue }) => (
                <Select
                  id="category"
                  inputRef={register}
                  name={name}
                  options={categoryOptions}
                  styles={Boolean(errors.category) ? CustomStylesInvalid : CustomStyles}
                  className={Boolean(errors.category) ? 'is-invalid' : ''}
                  placeholder="Pilih kategori..."
                  onChange={({ value }) => onChange(value)}
                  defaultValue={categoryOptions.find(({ value }) => value === defaultValue)}
                />
              )}
            />
            <FormFeedback>{errors.category?.message}</FormFeedback>
          </FormGroup>
          {isUpdate && (
            <FormGroup>
              <Label for="name">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={errors.link}
                defaultValue={classData.link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{'contoh : ' + paymentLinkUrl(link)}</small>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="paymentType">Tipe Pembayaran</Label>
            <Controller
              name="paymentType"
              control={control}
              defaultValue={paymentType}
              render={({ onChange, name, value: defaultPaymentType }) => (
                <Select
                  id={name}
                  name={name}
                  options={paymentTypeOptions}
                  defaultValue={paymentTypeOptions.find(obj => obj.value === defaultPaymentType)}
                  placeholder="Pilih Tipe Pembayaran"
                  styles={!!errors.paymentType ? CustomStylesInvalid : CustomStyles}
                  className={`${!!errors.paymentType ? 'is-invalid' : ''}`}
                  onChange={({ value }) => {
                    setPaymentType(value);
                    onChange(value);
                  }}
                />
              )}
            />
          </FormGroup>
          {/* <FormGroup>
            <Label for="amount">Harga*</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Rp</InputGroupText>
              </InputGroupAddon>
              <Input
                type="number"
                name="amount"
                id="amount"
                invalid={errors.amount}
                defaultValue={classData.amount}
                innerRef={register({
                  required: validator.required,
                  min: validator.min(1000),
                  max: validator.max(100000000)
                })}
              />
              <FormFeedback>{errors.amount?.message}</FormFeedback>
            </InputGroup>
            <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
          </FormGroup> */}
          {paymentType === 'paid' && (
            <FormGroup>
              <Label for="amount">Harga*</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Rp</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  name="amount"
                  id="amount"
                  invalid={!!errors.amount}
                  defaultValue={classData.amount}
                  innerRef={register({
                    required: validator.required,
                    min: validator.min(1000),
                    max: validator.max(100000000)
                  })}
                />
                <FormFeedback>{errors.amount?.message}</FormFeedback>
              </InputGroup>
              <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
            </FormGroup>
          )}
          {paymentType === 'voluntary' && (
            <FormGroup>
              <Label for="amount">Minimal Harga</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Rp</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  name="amount"
                  id="amount"
                  invalid={errors.amount}
                  placeholder="0+"
                  defaultValue={classData.amount}
                  innerRef={register({
                    // required: validator.required,
                    min: validator.min(0),
                    max: validator.max(100000000)
                  })}
                />
                <FormFeedback>{errors.amount?.message}</FormFeedback>
              </InputGroup>
              <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
            </FormGroup>
          )}
          {/* <FormGroup>
            <Label for="description">Deskripsi*</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              invalid={!!errors.description}
              defaultValue={classData.description}
              innerRef={register({ required: validator.required })}
            />
            <FormFeedback>{errors.description?.message}</FormFeedback>
          </FormGroup> */}

          <FormGroup>
            <Label for="description">Deskripsi*</Label>
            <Controller
              name="description"
              control={control}
              defaultValue={classData?.description && getEditorContent(classData.description)}
              rules={{
                required: { value: true, message: 'Wajib diisi' }
              }}
              render={({ onChange, value }) => (
                <RichEditor
                  onEditorStateChange={e => {
                    setIsDescriptionEmpty(!getPlainText(e));
                    onChange(e);
                  }}
                  editorState={value}
                  invalidState={Boolean(errors.description || isDescriptionEmpty)}
                />
              )}
            />
            <FormFeedback className={(isDescriptionEmpty || errors.description) && 'd-block'}>Wajib diisi</FormFeedback>
          </FormGroup>

          <MultipleImageInput productName={classData.name} setMultipleImageId={setMultipleImageId} multipleImage={classData.multipleImage} onLoading={setDisableSubmitButton} />

          <FormGroup>
            <Label for="embedUrl">
              Cover (video untuk promo) <small>(opsional)</small>
            </Label>
            <Input
              type="url"
              name="embedUrl"
              id="embedUrl"
              invalid={!!errors.embedUrl}
              defaultValue={classData.embedUrl}
              innerRef={register()}
              placeholder="https://youtube.com/embed/embedurl"
              onChange={e => setEmbedUrlData(e.target.value)}
            />
            <FormFeedback>{errors.embedUrl?.message}</FormFeedback>
            <small>
              Hanya mendukung video dari Youtube. Jika cover video diisi maka cover gambar tidak akan ditampilkan.
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="video">Cover Video</Label>
            <EmbedViewer embedUrl={embedUrlData} />
          </FormGroup>
          <FormGroup>
            <Label for="notes">Instruksi</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              invalid={errors.notes}
              defaultValue={classData.notes}
              innerRef={register()}
            />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Instruksi adalah catatan yang akan dilihat oleh pendaftar setelah melakukan pendaftaran/membayar. Anda
              bisa memasukkan instruksi masuk ke zoom, passwordnya, link gabung grup WA, formulir google, kontak cs dll
              disini
            </small>
          </FormGroup>

          <FormGroup>
            <Label for="terms">Syarat dan Ketentuan</Label>
            <Input
              type="textarea"
              name="terms"
              id="terms"
              invalid={errors.terms}
              defaultValue={classData.terms}
              innerRef={register()}
            />
            <FormFeedback>{errors.terms?.message}</FormFeedback>
          </FormGroup>
          <hr />
          <FormGroup>
            <Label for="startAt">Waktu Mulai Penjualan</Label>
            <div className="mb-1">
              <Controller
                name="startAt"
                control={control}
                defaultValue={classData.startAt || null}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal Mulai"
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={value ? new Date(value) : null}
                    minDate={new Date()}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
            <small>
              Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk
              langsung membuka penjualan
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="expiredAt">Batas Pendaftaran</Label>
            <div className="mb-1">
              <Controller
                name="expiredAt"
                control={control}
                defaultValue={classData.expiredAt || null}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal atau Kosongkan"
                    dateFormat="yyyy/MM/dd"
                    selected={value ? new Date(value) : null}
                    minDate={getValues('startAt') || tomorrow.setDate(tomorrow.getDate() + 1)}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.expiredAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.expiredAt?.message}</FormFeedback>
            <small>
              Kami akan menutup pendaftaran setelah tanggal ini (opsional), jika tidak diisi kami akan menutup webinar
              ini setelah acara dimulai.
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="eventStartAt">Mulai Pembelajaran*</Label>
            <div className="mb-1">
              <Controller
                name="eventStartAt"
                control={control}
                defaultValue={classData.eventStartAt || null}
                rules={{ required: { value: true, message: 'Wajib diisi' } }}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal Mulai"
                    dateFormat="yyyy/MM/dd"
                    selected={value ? new Date(value) : null}
                    minDate={getValues('expiredAt') || new Date()}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${
                      !!errors.eventStartAt ? 'is-invalid' : ''
                    }`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.eventStartAt?.message}</FormFeedback>
            <small>
              Kami akan membuka pembelajaran pada tanggal dan waktu yang anda pilih. Jika batas pendaftaran diisi maka
              minimal tanggal pembelajaran adalah tanggal batas pendaftaran.
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="eventEndAt">Batas Pembelajaran</Label>
            <div className="mb-1">
              <Controller
                name="eventEndAt"
                control={control}
                defaultValue={classData.eventEndAt || null}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal atau Kosongkan"
                    dateFormat="yyyy/MM/dd"
                    selected={value ? new Date(value) : null}
                    minDate={getValues('eventStartAt') || tomorrow.setDate(tomorrow.getDate() + 1)}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.eventEndAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.eventEndAt?.message}</FormFeedback>
            <small>Kami akan menutup pembelajaran setelah tanggal ini (opsional).</small>
          </FormGroup>
          <FormGroup>
            <Label for="limit">Maksimum Jumlah Peserta (Kuota / Qty)</Label>
            <Input
              type="number"
              name="limit"
              id="limit"
              invalid={errors.limit}
              defaultValue={classData.limit}
              innerRef={register()}
            />
            <FormFeedback>{errors?.limit?.message}</FormFeedback>
            <small>
              Kami akan menutup pendaftaran setelah melewati batas jumlah maksimal. Kosongkan untuk tanpa limit jumlah
              (unlimited)
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="gradeThreshold">Batas Nilai Quiz untuk akses sertifikat</Label>
            <Input
              type="number"
              name="gradeThreshold"
              id="gradeThreshold"
              invalid={!!errors.gradeThreshold}
              defaultValue={classData.gradeThreshold}
              innerRef={register()}
            />
            <FormFeedback>{errors.gradeThreshold?.message}</FormFeedback>
            <small>
              Kami akan menutup akses sertifikat sebelum customer mencapai batas minimal nilai rata - rata quiz.
              Kosongkan jika akses sertifikat tidak dibatasi nilai rata - rata quiz.
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="redirectUrl">Redirect URL</Label>
            <Input
              type="text"
              name="redirectUrl"
              id="redirectUrl"
              placeholder="https://websiteanda.com/terimakasih"
              invalid={errors.redirectUrl}
              defaultValue={classData.redirectUrl}
              innerRef={register({ pattern: validator.url })}
            />
            <FormFeedback>{errors?.redirectUrl?.message}</FormFeedback>
            <small>Pelanggan akan dibawa kehalaman ini setelah membayar (opsional /bisa dikosongkan)</small>
          </FormGroup>
          {/* <FormGroup>
            <CustomInput
              id="isBootcamp"
              label="Apakah anda ingin menjual produk ini di Bootcamp.id?"
              name="isBootcamp"
              type="switch"
              color="primary"
              innerRef={register}
              defaultChecked={classData?.isBootcamp ?? false}
            />
          </FormGroup>
          <Alert color="light" className="mb-20">
            Bootcamp.id adalah markeplace live learning dan bootcamp pertama di Indonesia yang mempertemukan pembelajar
            dan lembaga terbaik untuk upskilling dan mencapai tujuan karirnya. Baca syarat dan ketentuan disini.
          </Alert> */}

          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            loading
            isDisabled={disableSubmitButton}
            isLoading={submitCreateLoading || submitUpdateLoading}
            text={submitText()}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalBodyCohortCourse;
