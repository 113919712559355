import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Row
} from 'reactstrap';
import Select from 'react-select';
import { CustomStyles } from '../../../custom-styles/react-select-styles';
import { validator, paymentLinkUrl, REGEX_SLUG, formatQtyQurbanDivide } from '../../../../utils';
import RichEditor from '../../../rich-text-editor';
import DatePicker from '../../../date-time-picker';
import { Controller } from 'react-hook-form';
import MultipleImageInput from "../../../multiple-image-input";

const qurbanOptions = [
  { label: 'Sapi', value: 'SAPI' },
  { label: 'Kambing', value: 'KAMBING' },
  { label: 'Domba', value: 'DOMBA' },
  { label: 'Unta', value: 'UNTA' }
];

const QurbanForm = ({ data, isUpdate, hookForm, state, setState, setMultipleImageId, setDisableSubmitButton }) => {
  const { register, errors, control } = hookForm;
  const { link, date, invalidDescription, editorState, qurbanType } = state;
  const { setLink, setDate, setEditor, setQurbanType } = setState;
  const [existingStock, setExistingStock] = useState(
    data?.qty === 0 ? null : formatQtyQurbanDivide(Number(data.qty) - Number(data.totalAmountSold), qurbanType)
  );

  const handleEditor = value => setEditor(value);

  useEffect(() => {
    if (data?.qty !== 0) {
      setExistingStock(formatQtyQurbanDivide(Number(data.qty) - Number(data.totalAmountSold), qurbanType));
    }

    return () => {
      setExistingStock(null);
    };
  }, [data, qurbanType]);

  return (
    <div>
      <FormGroup>
        <Label>Jenis Hewan Qurban</Label>
        <Select
          options={qurbanOptions}
          styles={CustomStyles}
          placeholder="Pilih jenis hewan qurban..."
          onChange={({ value }) => setQurbanType(value)}
          defaultValue={qurbanOptions.find(({ value }) => value === qurbanType)}
        />
        {qurbanType === 'SAPI' && <small>1 Sapi dapat di beli oleh 7 orang/paket</small>}
        {qurbanType === 'UNTA' && <small>1 Unta dapat di beli oleh 10 orang/paket</small>}
      </FormGroup>

      <FormGroup>
        <Label for="name">Judul Qurban*</Label>
        <Input
          type="text"
          name="name"
          id="name"
          invalid={Boolean(errors.name)}
          defaultValue={data.name}
          placeholder="Contoh: Jual Sapi Limosin"
          innerRef={register({
            required: validator.required,
            minLength: validator.minLength(5)
          })}
        />
        <FormFeedback>{errors.name?.message}</FormFeedback>
      </FormGroup>

      {isUpdate && (
        <FormGroup>
          <Label for="name">Slug*</Label>
          <Input
            type="text"
            name="link"
            id="link"
            invalid={errors.link}
            defaultValue={data.link}
            onChange={e => setLink(e.target.value)}
            innerRef={register({
              required: validator.required,
              pattern: validator.pattern(REGEX_SLUG)
            })}
          />
          <FormFeedback>{errors.link?.message}</FormFeedback>
          <small>{'contoh : ' + paymentLinkUrl(link)}</small>
        </FormGroup>
      )}

      <FormGroup>
        <Label for="amount">Harga 1 Ekor*</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Rp</InputGroupText>
          </InputGroupAddon>
          <Input
            type="number"
            name="amount"
            id="amount"
            invalid={Boolean(errors.amount)}
            defaultValue={data.amount}
            innerRef={register({
              required: validator.required,
              min: validator.min(1_000),
              max: validator.max(10_000_000_000)
            })}
          />
          <FormFeedback>{errors.amount?.message}</FormFeedback>
        </InputGroup>
        <small>Harga menggunakan mata uang IDR(Rp)</small>
      </FormGroup>

      <FormGroup>
        <Label for="description">Deskripsi Qurban*</Label>
        <RichEditor onEditorStateChange={handleEditor} editorState={editorState} invalidState={invalidDescription} />
        <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>Wajib diisi</FormFeedback>
      </FormGroup>

      <MultipleImageInput productName={data.name} setMultipleImageId={setMultipleImageId} multipleImage={data.multipleImage} onLoading={setDisableSubmitButton} />

      <FormGroup>
        <Label for="startAt">Waktu Mulai Penjualan</Label>
        <div className="mb-1">
          <Controller
            name="startAt"
            control={control}
            defaultValue={data.startAt || null}
            render={({ onChange, name, value }) => (
              <DatePicker
                id={name}
                placeholderText="Pilih Tanggal Mulai"
                showTimeSelect
                dateFormat="yyyy/MM/dd HH:mm"
                timeFormat="HH:mm"
                selected={value ? new Date(value) : null}
                minDate={new Date()}
                className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                onChange={onChange}
              />
            )}
          />
        </div>
        <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
        <small>
          Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk langsung
          membuka penjualan
        </small>
      </FormGroup>
      <FormGroup>
        <Label for="expiredDate">Batas Waktu Qurban</Label>
        <div className="mb-1">
          <DatePicker
            id="expiredDate"
            placeholderText="Pilih tanggal atau kosongkan"
            dateFormat="yyyy/MM/dd"
            selected={date ? new Date(date) : null}
            minDate={new Date()}
            className="rui-datetimepicker form-control w-auto"
            onChange={d => setDate(d)}
          />
        </div>
        <small>Kami akan menutup qurban setelah tanggal ini. Kosongkan untuk tidak terbatas</small>
      </FormGroup>

      {!isUpdate ? (
        <FormGroup>
          <Label for="qty">Stok Hewan Tersedia (ekor)*</Label>
          <Input
            type="number"
            name="qty"
            id="qty"
            invalid={Boolean(errors.qty)}
            defaultValue={formatQtyQurbanDivide(data.qty, data?.qurbanType)}
            innerRef={register({
              min: validator.min(1),
              max: validator.max(10_000_000)
            })}
          />
          <small>Tidak perlu diisi untuk stok tidak terbatas</small>
          <FormFeedback>{errors.qty?.message}</FormFeedback>
        </FormGroup>
      ) : (
        <Row>
          <FormGroup className="col-6">
            <Label for="qty">Stok Hewan Tersedia (ekor)</Label>
            <Input
              type="number"
              placeholder={data.qty === 0 ? 'Stok Tidak Terbatas' : null}
              value={existingStock}
              disabled
            />
          </FormGroup>
          <FormGroup className="col-6 pl-0">
            <Label for="qty">Stok Baru</Label>
            <Input
              type="number"
              name="qty"
              id="qty"
              className="col-6 mw-100"
              invalid={Boolean(errors.qty)}
              innerRef={register({
                min: validator.min(1),
                max: validator.max(10_000_000)
              })}
            />
            <FormFeedback>{errors.qty?.message}</FormFeedback>
          </FormGroup>
        </Row>
      )}
      <FormGroup>
        <Label for="notes">Catatan</Label>
        <Input
          type="textarea"
          name="notes"
          id="notes"
          invalid={errors.notes}
          defaultValue={data.notes}
          innerRef={register()}
        />
        <FormFeedback>{errors.notes?.message}</FormFeedback>
        <small>Catatan akan dilihat oleh pendaftar/pembeli setelah melakukan pendaftaran/membayar (opsional). </small>
      </FormGroup>
      <FormGroup>
        <Label for="redirectUrl">Redirect URL</Label>
        <Input
          type="text"
          name="redirectUrl"
          id="redirectUrl"
          placeholder="https://websiteanda.com/terimakasih"
          invalid={Boolean(errors.redirectUrl)}
          defaultValue={data.redirectUrl}
          innerRef={register({ pattern: validator.url })}
        />
        <FormFeedback>{errors?.redirectUrl?.message}</FormFeedback>
        <small>Donatur akan dibawa kehalaman ini setelah membayar (opsional / bisa dikosongkan)</small>
      </FormGroup>
    </div>
  );
};

export default QurbanForm;
