import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { EditorState } from 'draft-js';
import { Modal, ModalBody, Form, Label, Button, Alert } from 'reactstrap';
import { GQL_PAYMENTLINK_CREATE_BY_SLUG, GQL_PAYMENTLINK_UPDATE_BY_SLUG } from '../../../gqls';
import { sanitizeDOM, slug, formatQtyQurbanMultiply } from '../../../utils';
import { updatePaymentLinks } from '../../../actions/payment-links';
import { CustomStyles } from '../../custom-styles/react-select-styles';
import { getPlainText, getContentString, getEditorContent } from '../../rich-text-editor';
import Icon from '../../icon';
import SubmitButton from '../../submit-button';
import DonationForm from './components/donation-form';
import QurbanForm from './components/qurban-form';
import WaqafForm from './components/waqaf-form';

const ModalFundraising = ({ isOpen = false, onClose, onSuccess, data }) => {
  const dispatch = useDispatch();
  const stateEdit = EditorState.createEmpty();
  const { register, handleSubmit, errors, control } = useForm();

  const [submitError, setSubmitError] = useState(false);
  const [invalidDescription, setInvalidDescription] = useState(false);

  const [description, setDescription] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [fundraisingType, setFundraisingType] = useState('');
  const [qurbanType, setQurbanType] = useState(data?.qurbanType ? data?.qurbanType : null);
  const [date, setDate] = useState(data ? data.expiredAt : null);
  const [link, setLink] = useState(data ? data.link : '');
  const [editorState, setEditorState] = useState(stateEdit);
  const [check, setCheck] = useState(true);

  const [multipleImageId, setMultipleImageId] = useState('');
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const setEditor = content => {
    setEditorState(content);
    setDescription(getContentString(content));
    setTextDescription(getPlainText(content));
  };

  const [insertPaymentLinkBySlug, { data: newPaymentLink, loading: submitCreateLoading }] = useMutation(
    GQL_PAYMENTLINK_CREATE_BY_SLUG
  );

  const [editPaymentLinkBySlug, { loading: submitUpdateLoading }] = useMutation(GQL_PAYMENTLINK_UPDATE_BY_SLUG);

  const isUpdate = data ? true : false;
  if (!data) data = {};

  useEffect(() => {
    if (isUpdate && data.subType !== null) {
      setFundraisingType(data.subType);
    } else if (isUpdate && data.subType === null) {
      setFundraisingType('FUNDRAISING_DONATION');
    }
  }, [data]);

  useEffect(() => {
    if (textDescription.length <= 0 && !check) {
      setInvalidDescription(true);
    } else {
      setInvalidDescription(false);
    }
  }, [textDescription]);

  useEffect(() => {
    if (isUpdate) {
      const content = getEditorContent(data.description);
      setEditor(content);
    }
  }, [data]);

  const onSubmit = async values => {
    if (textDescription.length <= 0) {
      setCheck(false);
      setInvalidDescription(true);
      return;
    }

    const { isTargetVisible, name } = values;

    if (values.target) values.target = parseFloat(values.target);
    if (values.amount) values.amount = parseFloat(values.amount);

    if (date !== null) setDate(date);
    if (fundraisingType !== 'FUNDRAISING_QURBAN') {
      values.fundraisingSettings = JSON.stringify({ isTargetVisible });
      delete values.isTargetVisible;
    }

    if (fundraisingType === 'FUNDRAISING_QURBAN') {
      if (isUpdate && !/SAPI|UNTA/.test(qurbanType)) {
        values.qty = Number(data.qty) + Number(values.qty);
      } else {
        values.qty = Number(values.qty);
      }

      if (qurbanType) {
        values.qurbanType = qurbanType;
      }

      if (isUpdate && qurbanType === 'SAPI') {
        values.qty = Number(data.qty) + formatQtyQurbanMultiply(values.qty, qurbanType);
      } else if (qurbanType === 'SAPI') {
        values.qty = formatQtyQurbanMultiply(values.qty, qurbanType);
      }

      if (isUpdate && qurbanType === 'UNTA') {
        values.qty = Number(data.qty) + formatQtyQurbanMultiply(values.qty, qurbanType);
      } else if (qurbanType === 'UNTA') {
        values.qty = formatQtyQurbanMultiply(values.qty, qurbanType);
      }
    }

    const paymentLinkObject = {
      ...values,
      ...(multipleImageId && { multipleImageId }),
      expiredAt: date,
      status: 'active',
      description: sanitizeDOM(description)
    };

    try {
      if (isUpdate) {
        paymentLinkObject.id = data.id;
        await editPaymentLinkBySlug({
          variables: { input: paymentLinkObject }
        });

        dispatch(updatePaymentLinks(Math.random()));
      } else {
        paymentLinkObject.type = 'fundraising';
        paymentLinkObject.link = slug(name);
        paymentLinkObject.subType = fundraisingType;

        await insertPaymentLinkBySlug({
          variables: { input: paymentLinkObject }
        });

        //segment tracking
        window.analytics.track('Create Fundraising', values);
      }
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Fundraising - ERROR', values);
    }
  };

  if (newPaymentLink && newPaymentLink.insertPaymentLinkBySlug) {
    onSuccess(newPaymentLink.insertPaymentLinkBySlug.id, fundraisingType);
  }


  const fundraisingForm = type => {
    switch (type) {
      case 'FUNDRAISING_DONATION':
        return (
          <DonationForm
            data={data}
            isUpdate={isUpdate}
            hookForm={{ register, errors, control }}
            state={{
              link,
              date,
              invalidDescription,
              editorState
            }}
            setState={{ setLink, setDate, setEditor }}
            setMultipleImageId={setMultipleImageId}
            setDisableSubmitButton={setDisableSubmitButton}
          />
        );
      case 'FUNDRAISING_QURBAN':
        return (
          <QurbanForm
            data={data}
            isUpdate={isUpdate}
            hookForm={{ register, errors, control }}
            state={{
              link,
              date,
              invalidDescription,
              editorState,
              qurbanType
            }}
            setState={{ setLink, setDate, setEditor, setQurbanType }}
            setMultipleImageId={setMultipleImageId}
            setDisableSubmitButton={setDisableSubmitButton}
          />
        );
      case 'FUNDRAISING_WAQAF':
        return (
          <WaqafForm
            data={data}
            isUpdate={isUpdate}
            hookForm={{ register, errors, control }}
            state={{
              link,
              date,
              invalidDescription,
              editorState
            }}
            setState={{ setLink, setDate, setEditor }}
            setMultipleImageId={setMultipleImageId}
            setDisableSubmitButton={setDisableSubmitButton}
          />
        );
      default:
        return '';
    }
  };

  const categoryOptions = [
    { label: 'Donasi', value: 'FUNDRAISING_DONATION' },
    { label: 'Qurban', value: 'FUNDRAISING_QURBAN' },
    { label: 'Wakaf', value: 'FUNDRAISING_WAQAF' }
  ];

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Penggalangan Dana</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <Alert color="light" className="mb-20">
          Baca mengenai aturan penggalangan dana{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/mayarid/Panduan-Menggalang-Dana-Mayar-de545450dea64b72b1974a22fc7c34b7"
          >
            disini
          </a>
          . Mayar berhak menutup sepihak penggalangan dana yang melanggar aturan.
        </Alert>
        {submitError && (
          <div className="mb-10">
            <Alert color="danger">Error! Silahkan coba lagi atau hubungi CS</Alert>
          </div>
        )}
        {!isUpdate && (
          <div className="mb-20">
            <Label>Tipe Penggalangan Dana*</Label>
            <Select
              options={categoryOptions}
              styles={CustomStyles}
              placeholder="Pilih kategori..."
              onChange={({ value }) => setFundraisingType(value)}
              defaultValue={categoryOptions.find(({ value }) => value === fundraisingType)}
            />
          </div>
        )}
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
          {fundraisingForm(fundraisingType)}
          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            text={`${isUpdate ? 'Edit' : 'Buat'} Penggalangan Dana`}
            loading
            isLoading={submitCreateLoading || submitUpdateLoading}
            isDisabled={disableSubmitButton}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalFundraising;
